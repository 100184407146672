import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const { SVG, Group, Path, Polygon, Rectangle } = styledTags;

const Logo = (props) => {
  const colors = {
      base: '#FFFFFF',
      dark: '#212020',
      red: '#FA0021',
      yellow: '#FFC700',
      green: '#00C43D',
      blue: '#0050D9',
      violet: '#6E00FF',
    }

  return (
    <SVG xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1877.41 1696.56' {...props} >
        <Rectangle fill='none' width='1877.41' height='1696.56'/>
        <Path fill={colors.base} d='M847.49,276.34'/>
        <Path fill={colors.base} d='M1306.91,715.12l-43.56-85.78c-15.64-30.8-42.34-53.67-75.18-64.39L992.62,501.1
            c-5.99-1.95-12.16-2.95-18.36-2.95c-14.04,0-27.26,4.98-37.66,13.82L785.84,250.86L421.05,882.69h494.97
            c0.72,0.25,1.44,0.53,2.17,0.77l276.18,90.16c9.95,3.25,20.29,4.9,30.72,4.9c0,0,0,0,0,0c43.22,0,81.26-27.55,94.64-68.55
            l26.54-81.29C1360.41,785.37,1343.07,739.25,1306.91,715.12z'/>
        <Path fill={colors.dark} d='M1279.16,741.31l-0.11-0.21l-325.5-125.94l313.59,102.49v0l-14-27.57c0,0,0,0,0,0l-22.43-44.17
            c-11.13-21.93-30.52-38.54-53.9-46.17v0L981.26,535.9c-18.55-6.06-35.24,13.2-26.61,30.7c2.62,5.32,7.29,9.36,12.93,11.21
            l165.9,54.16c0,0,28.67,7.52,54.36,6.64c4.34,4.16,8,9.04,10.77,14.5l8.28,16.31l-160.66-52.45l-1.48-0.48v0l-111.01-36.24
            l-147.9-256.18L484.45,846.09h96.61l204.78-467.65L643.98,846.09h239.64c-9.86-14.34-15.91-31.72-15.54-52.7
            c6.03,24.62,29.71,42.08,54.86,52.7h0.03c2.12,0.97,4.3,1.83,6.57,2.57l276.18,90.17c32.84,10.72,68.49-7.38,79.21-40.22
            l26.54-81.29C1321.3,787.21,1306.91,754.78,1279.16,741.31z'/>
        <Path fill={colors.red} d='M1277.25,740.41c-0.01,0-0.02-0.01-0.02-0.01c-0.82-0.36-1.66-0.7-2.5-1.04c-0.18-0.07-0.36-0.15-0.54-0.22
            c-0.96-0.37-1.94-0.72-2.93-1.04l-221.7-72.38l-0.03-0.01l-54.42-17.77L837.6,596.33l474.59,218.63
            C1321.72,782.33,1301.72,751.27,1277.25,740.41z'/>
        <Polygon fill={colors.yellow} points='1304.58,838.44 851.54,647.19 1312.19,814.96'/>
        <Polygon fill={colors.green} points='1292.54,875.12 1304.58,838.44 868.07,679.25 '/>
        <Polygon fill={colors.green} points='1284.94,898.61 883.53,726.16 1292.54,875.12'/>
        <Path fill={colors.violet} d='M1284.85,898.83L897.8,772.47l349.92,165.2C1264.69,931.12,1278.73,917.33,1284.85,898.83z'/>
        <Path fill={colors.dark} d='M890.99,506.19'/>
        <Group fill={colors.base}>
            <Path d='M539.96,1150.69c-10.31,12.78-29.28,21.31-54.99,21.31c-39.45,0-63.92-18.7-63.92-51.28
                c0-32.58,24.47-51.27,63.92-51.27c23.78,0,40.97,8.38,51,17.73l-20.48,15.12c-8.25-7.42-16.91-10.72-29.83-10.72
                c-23.37,0-36.15,10.31-36.15,29.14c0,18.97,13.2,29.28,36.57,29.28c11.82,0,21.17-2.34,27.91-8.52v-8.66h-31.89v-19.66h57.87
                V1150.69z'/>
            <Path d='M664.37,1146.29v22.55h-92.79v-96.23h28.04v73.68H664.37z'/>
            <Path d='M683.06,1120.72c0-32.58,24.61-51.27,62.69-51.27c38.08,0,62.82,18.69,62.82,51.27
                c0,32.58-24.74,51.28-62.82,51.28C707.67,1172,683.06,1153.3,683.06,1120.72z M779.98,1120.72c0-18.15-13.06-28.46-34.23-28.46
                c-21.03,0-34.23,10.31-34.23,28.46c0,18.15,13.06,28.46,34.23,28.46C766.92,1149.18,779.98,1138.87,779.98,1120.72z'/>
            <Path d='M952.36,1142.44c0,16.63-14.98,26.4-38.63,26.4h-74.37v-96.23h68.87c23.64,0,38.63,9.49,38.63,24.88
                c0,10.86-7.56,20.35-22,21.17C939.99,1119.62,952.36,1127.59,952.36,1142.44z M867.13,1110.96h36.43c9.35,0,15.53-2.06,15.53-9.48
                c0-7.29-6.19-9.35-15.53-9.35h-36.43V1110.96z M923.63,1139.14c0-7.84-5.91-10.04-15.81-10.04h-40.69v20.21h40.69
                C917.72,1149.31,923.63,1146.98,923.63,1139.14z'/>
            <Path d='M1058.35,1150.42h-52.79l-9.07,18.42h-29.42l49.76-96.23h30.66l49.76,96.23h-29.83L1058.35,1150.42z
                M1048.59,1130.48l-16.63-34.09l-16.77,34.09H1048.59z'/>
            <Path d='M1214.37,1146.29v22.55h-92.79v-96.23h28.04v73.68H1214.37z'/>
            <Path d='M426.54,1286.66v-96.23h28.04v96.23H426.54z'/>
            <Path d='M604.83,1190.44v96.23h-24.61l-62.14-63.24v63.24h-27.08v-96.23h29.69l57.05,58.84v-58.84H604.83z'/>
            <Path d='M641.12,1286.66v-96.23h28.04v96.23H641.12z'/>
            <Path d='M804.29,1212.84h-40v73.82h-28.04v-73.82h-39.73v-22.41h107.78V1212.84z'/>
            <Path d='M831.37,1286.66v-96.23h28.04v96.23H831.37z'/>
            <Path d='M975.3,1268.24h-52.79l-9.07,18.42h-29.42l49.76-96.23h30.65l49.76,96.23h-29.83L975.3,1268.24z
                M965.54,1248.31l-16.63-34.09l-16.77,34.09H965.54z'/>
            <Path d='M1122.25,1212.84h-40v73.82h-28.04v-73.82h-39.73v-22.41h107.78V1212.84z'/>
            <Path d='M1149.33,1286.66v-96.23h28.04v96.23H1149.33z'/>
            <Path d='M1232.22,1190.44l31.76,69.29l31.62-69.29h29.97l-47.15,96.23h-29.42l-47.01-96.23H1232.22z'/>
            <Path d='M1452.58,1265.22v21.44h-102.69v-96.23h101.18v21.44h-73.55v15.81h60.62v20.48h-60.62v17.05H1452.58z'/>
            <Path d='M427.65,1324.3v15.96h27.99v5.08h-27.99v19.47h-4.76v-45.59h37.74v5.08H427.65z'/>
            <Path d='M464.93,1342.02c0-14.85,9.07-24.16,24.14-24.16c15.07,0,24.14,9.31,24.14,24.16s-9.07,24.16-24.14,24.16
                C474,1366.18,464.93,1356.87,464.93,1342.02z M508.34,1342.02c0-12.24-7.59-19.08-19.26-19.08c-11.67,0-19.26,6.84-19.26,19.08
                s7.59,19.08,19.26,19.08C500.74,1361.1,508.34,1354.26,508.34,1342.02z'/>
            <Path d='M560.23,1364.81l-13.15-17.45h-18.87v17.45h-4.82v-45.59h26.63c8.84,0,15.19,5.08,15.19,14.07
                c0,7.94-4.99,12.89-12.3,13.87l13.54,17.65H560.23z M549.69,1342.28c6.4,0,10.65-2.8,10.65-8.99s-4.25-8.99-10.65-8.99h-21.53
                v17.98H549.69z'/>
            <Path d='M637,1342.02c0,14-9.29,22.79-23.8,22.79h-19.26v-45.59h19.26C627.71,1319.22,637,1328.01,637,1342.02z
                M632.13,1342.02c0-11.66-7.99-17.72-19.26-17.72H598.7v35.43h14.17C624.14,1359.73,632.13,1353.67,632.13,1342.02z'/>
            <Path d='M647.2,1364.81v-45.59h4.76v45.59H647.2z'/>
            <Path d='M664.82,1319.22l18.3,39.66l18.3-39.66h5.27l-21.02,45.59h-5.21l-21.02-45.59H664.82z'/>
            <Path d='M752.53,1359.73v5.08h-38.36v-45.59h37.79v5.08h-33.03v14.79h27.99v5.08h-27.99v15.56H752.53z'/>
            <Path d='M799.16,1364.81l-13.15-17.45h-18.87v17.45h-4.82v-45.59h26.63c8.84,0,15.19,5.08,15.19,14.07
                c0,7.94-4.99,12.89-12.3,13.87l13.54,17.65H799.16z M788.62,1342.28c6.4,0,10.65-2.8,10.65-8.99s-4.25-8.99-10.65-8.99h-21.53
                v17.98H788.62z'/>
            <Path d='M854.4,1351.59c0,8.4-7.42,14.59-20.34,14.59c-8.67,0-17-3.13-23.17-9.38l3.34-4.1
                c5.78,5.86,12.18,8.4,20.11,8.4c9.58,0,15.18-3.45,15.18-9.12c0-5.54-5.84-6.9-16.54-7.95c-10.6-1.04-20.74-3.71-20.74-12.7
                c0-8.6,9.41-13.48,20-13.48c8.61,0,15.7,3.45,20.28,8.14l-3.74,3.65c-3.85-4.36-9.69-6.64-16.32-6.71
                c-7.08-0.06-15.36,2.22-15.36,8.21c0,5.67,7.48,6.84,16.83,7.69C846.01,1339.93,854.4,1342.6,854.4,1351.59z'/>
            <Path d='M863.69,1364.81v-45.59h4.76v45.59H863.69z'/>
            <Path d='M918.48,1324.17h-18.75v40.64h-4.76v-40.64h-18.7v-4.95h42.21V1324.17z'/>
            <Path d='M926.46,1319.22l16.66,24.68l16.55-24.68h5.67l-19.89,29.63v15.96h-4.76v-15.96l-19.94-29.63H926.46z'/>
            <Path d='M971.22,1362.4c0,3.45-1.7,8.34-4.65,11.53l-1.53-1.5c2.27-3,3.12-4.88,3.4-7.23
                c-0.17,0.2-0.57,0.26-0.91,0.26c-1.76,0-3.17-1.5-3.17-3.65c0-2.02,1.42-3.58,3.34-3.58
                C969.75,1358.23,971.22,1359.86,971.22,1362.4z'/>
            <Path d='M1038.14,1359.73v5.08h-38.36v-45.59h37.79v5.08h-33.03v14.79h27.99v5.08h-27.99v15.56H1038.14z'/>
            <Path d='M1083.46,1361.62l5.95,7.36l-3.06,3.26l-6.74-8.34c-3.29,1.5-7.2,2.28-11.62,2.28
                c-15.07,0-24.14-9.31-24.14-24.16s9.07-24.16,24.14-24.16s24.14,9.31,24.14,24.16C1092.13,1350.68,1089.01,1357.45,1083.46,1361.62
                z M1076.26,1359.73l-6.4-7.94l3.06-3.26l7.31,9.05c4.48-3.12,7.03-8.47,7.03-15.56c0-12.24-7.59-19.08-19.26-19.08
                s-19.26,6.84-19.26,19.08s7.59,19.08,19.26,19.08C1071.05,1361.1,1073.83,1360.64,1076.26,1359.73z'/>
            <Path d='M1143.4,1319.22v24.1c0,13.28-7.25,22.86-20.91,22.86s-20.96-9.58-20.96-22.86v-24.1h4.76v23.77
                c0,11.2,5.84,18.1,16.2,18.1c10.31,0,16.15-6.9,16.15-18.1v-23.77H1143.4z'/>
            <Path d='M1155.75,1364.81v-45.59h4.76v45.59H1155.75z'/>
            <Path d='M1210.54,1324.17h-18.75v40.64h-4.76v-40.64h-18.7v-4.95h42.21V1324.17z'/>
            <Path d='M1218.53,1319.22l16.66,24.68l16.55-24.68h5.67l-19.89,29.63v15.96h-4.76v-15.96l-19.94-29.63H1218.53z'/>
            <Path d='M1317.46,1364.81l-4.25-3.58c-3.63,3.13-8.67,4.88-15.19,4.88c-11.33,0-19.83-6.58-19.83-15.17
                c0-5.28,3.17-10.16,8.22-12.57c-2.04-2.21-3.4-4.62-3.4-8.14c0-7.43,6.01-12.31,14.56-12.31c9.41,0,14.45,5.8,16.77,12.05
                l-4.19,1.95c-2.21-5.86-6.12-9.05-12.64-9.05c-6.06,0-9.63,2.8-9.63,7.1c0,4.04,3.4,6.51,8.33,10.68l16.55,13.94
                c1.87-2.8,2.83-6.51,2.89-11.14h4.65c-0.06,5.6-1.36,10.42-3.91,14.2l8.5,7.16H1317.46z M1309.47,1358.1l-18.08-15.3
                c-0.62-0.52-1.19-0.98-1.76-1.5c-4.59,2.08-6.51,5.6-6.51,9.31c0,7.29,7.25,10.55,15.24,10.55
                C1302.89,1361.17,1306.63,1360.12,1309.47,1358.1z'/>
            <Path d='M423.51,1442.96v-45.59h4.76v45.59H423.51z'/>
            <Path d='M483.57,1397.37v45.59h-4.19l-33.32-38.62v38.62h-4.65v-45.59h5.16l32.35,37.58v-37.58H483.57z'/>
            <Path d='M533.43,1430.52l4.53,2.6c-3.74,6.84-10.65,11.2-20.34,11.2c-14.79,0-23.85-9.38-23.85-24.16
                c0-14.79,9.07-24.16,24.02-24.16c9.52,0,16.43,4.43,20.17,11.2l-4.53,2.6c-3.12-6.06-8.78-8.73-15.7-8.73
                c-11.5,0-19.09,6.51-19.09,19.08s7.59,19.08,19.09,19.08C524.64,1439.25,530.37,1436.64,533.43,1430.52z'/>
            <Path d='M582.09,1437.88v5.08h-35.13v-45.59h4.76v40.51H582.09z'/>
            <Path d='M628.04,1397.37v24.1c0,13.28-7.25,22.86-20.91,22.86s-20.96-9.58-20.96-22.86v-24.1h4.76v23.77
                c0,11.2,5.84,18.1,16.2,18.1c10.31,0,16.15-6.9,16.15-18.1v-23.77H628.04z'/>
            <Path d='M680.05,1429.74c0,8.4-7.42,14.59-20.34,14.59c-8.67,0-17-3.13-23.17-9.38l3.34-4.1
                c5.78,5.86,12.18,8.4,20.11,8.4c9.58,0,15.18-3.45,15.18-9.12c0-5.54-5.84-6.9-16.54-7.95c-10.6-1.04-20.74-3.71-20.74-12.7
                c0-8.6,9.41-13.48,20-13.48c8.61,0,15.7,3.45,20.28,8.14l-3.74,3.65c-3.85-4.36-9.69-6.64-16.32-6.71
                c-7.08-0.06-15.36,2.22-15.36,8.21c0,5.67,7.48,6.84,16.83,7.69C671.67,1418.08,680.05,1420.75,680.05,1429.74z'/>
            <Path d='M689.34,1442.96v-45.59h4.76v45.59H689.34z'/>
            <Path d='M704.3,1420.17c0-14.85,9.07-24.16,24.14-24.16c15.07,0,24.14,9.31,24.14,24.16s-9.07,24.16-24.14,24.16
                C713.37,1444.33,704.3,1435.01,704.3,1420.17z M747.7,1420.17c0-12.24-7.59-19.08-19.26-19.08c-11.67,0-19.26,6.84-19.26,19.08
                s7.59,19.08,19.26,19.08C740.11,1439.25,747.7,1432.41,747.7,1420.17z'/>
            <Path d='M804.93,1397.37v45.59h-4.19l-33.32-38.62v38.62h-4.65v-45.59h5.16l32.35,37.58v-37.58H804.93z'/>
            <Path d='M836.2,1442.96v-45.59h4.76v45.59H836.2z'/>
            <Path d='M896.26,1397.37v45.59h-4.19l-33.32-38.62v38.62h-4.65v-45.59h5.16l32.35,37.58v-37.58H896.26z'/>
            <Path d='M964.25,1430.52l4.53,2.6c-3.74,6.84-10.65,11.2-20.34,11.2c-14.79,0-23.85-9.38-23.85-24.16
                c0-14.79,9.07-24.16,24.02-24.16c9.52,0,16.43,4.43,20.17,11.2l-4.53,2.6c-3.12-6.06-8.78-8.73-15.7-8.73
                c-11.5,0-19.09,6.51-19.09,19.08s7.59,19.08,19.09,19.08C955.46,1439.25,961.19,1436.64,964.25,1430.52z'/>
            <Path d='M1018.86,1397.37v24.1c0,13.28-7.25,22.86-20.91,22.86c-13.66,0-20.96-9.58-20.96-22.86v-24.1h4.76v23.77
                c0,11.2,5.84,18.1,16.2,18.1c10.31,0,16.15-6.9,16.15-18.1v-23.77H1018.86z'/>
            <Path d='M1068.04,1442.96l-13.15-17.45h-18.87v17.45h-4.82v-45.59h26.63c8.84,0,15.19,5.08,15.19,14.07
                c0,7.94-4.99,12.89-12.3,13.87l13.54,17.65H1068.04z M1057.5,1420.42c6.4,0,10.65-2.8,10.65-8.99s-4.25-8.99-10.65-8.99h-21.53
                v17.98H1057.5z'/>
            <Path d='M1118.75,1437.88v5.08h-35.13v-45.59h4.76v40.51H1118.75z'/>
            <Path d='M1126.62,1442.96v-45.59h4.76v45.59H1126.62z'/>
            <Path d='M1186.68,1397.37v45.59h-4.19l-33.32-38.62v38.62h-4.65v-45.59h5.16l32.35,37.58v-37.58H1186.68z'/>
            <Path d='M1243.51,1431.89c-3.29,7.1-11.22,12.44-22.21,12.44c-15.35,0-24.42-9.31-24.42-24.16s9.07-24.16,24.25-24.16
                c9.69,0,16.2,4.17,20.17,9.51l-4.14,3.32c-3.57-5.14-8.84-7.75-16.04-7.75c-11.84,0-19.38,6.45-19.38,19.08
                c0,12.63,7.59,19.08,19.72,19.08c7.88,0,14.34-2.67,17.28-8.99v-5.93h-18.02v-5.08h22.78V1431.89z'/>
        </Group>
    </SVG>
  )
}

export default Logo;
