import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Styles
import 'styles/css/base.css';
import 'styles/css/keyframes.css';

import { LandingPageApp } from 'apps';

ReactDOM.render(
  <React.StrictMode>
    <LandingPageApp />
  </React.StrictMode>,
  document.getElementById('root')
);

