/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import tw from 'twin.macro';
import React from 'react';

/*
  Function to create HTML tags as custom React components that can have 
  styles attached to them, using Twin Macro.
*/
const createStyledTag = (as = 'div') => {
  const StyledTag = tw(as)``;
  return React.forwardRef((props, ref) => (
      <StyledTag ref={ref} {...props}>
        {props.children}
      </StyledTag>
  ))
}

/*
  A mapping of styled React components to create and their corresponding HTML tag.
*/
const componentTagMap = {
  // Basic
  Division : 'div', Paragraph : 'p', Span : 'span',
  Image : 'img', Anchor : 'a', Button : 'button', 
  Heading : 'h1', Break : 'br', Quote : 'blockquote',
  
  // Sections
  Main : 'main', Section : 'section', Article : 'article',
  Aside : 'aside', Container : 'div', Row : 'div', Column : 'div',
  Navigation : 'nav', Header : 'header', Footer: 'footer',
  
  // Lists
  UnorderedList : 'ul', OrderedList : 'ol', ListItem : 'li',
  DescriptionList: 'dl', Description: 'dd', Term: 'dt',
  
  // Time
  Time : 'time',

  // Forms
  Form: 'form', FieldSet: 'fieldset', OptionGroup: 'optgroup',
  Label: 'label', Input: 'input', TextArea: 'textarea', 
  Select: 'select', Option: 'option', Output: 'output',
  
  // Tables
  Table : 'table', TableBody : 'tbody', TableHeader: 'thead',
  TableFooter: 'tfoot', TableRow : 'tr', TableCell : 'td',
  TableHeaderCell: 'th', 
  
  // SVG
  SVG : 'svg', Title : 'title', Group : 'g', Definitions : 'defs',
  Pattern: 'pattern', Path : 'path', Circle : 'circle',
  Ellipse: 'ellipse', Polygon : 'polygon', Rectangle : 'rect', 
  Text : 'text',
};

const styledTags = {};

Object.entries(componentTagMap).forEach(([key, value]) => {
  styledTags[[key]] = createStyledTag(value)
});

export default styledTags;