import React from 'react';
// eslint-disable-next-line
import tw from "twin.macro";

import styledTags from 'components/styledtags';
import Logo from 'assets/Logo'; 

const { Division, Section } = styledTags;

const LangingPageApp = () => {
    return (
        <Section tw='h-screen w-screen flex items-center justify-center bg-black'>
            <Division tw='h-3/4'><Logo tw='h-full' /></Division>
        </Section>
    )
}

export default LangingPageApp;